
import RoundAvatar from '@/components/common/img/RoundAvatar.vue';
import ReviewUser from '@/components/review/ReviewUser.vue';
import ReviewStars from '@/components/review/ReviewStars.vue';

export default {
  name: 'ReviewHeader',
  components: { RoundAvatar, ReviewUser, ReviewStars },
  props: {
    user: {
      type: Object,
      default: () => ({ profile: null, name: null }),
    },
    children: { type: Object, default: () => ({}) },
    createAt: { type: String, default: null },
    rating: { type: Number, default: 0 },
  },
  computed: {
    // 리뷰 작성 당시의 유저 아이의 한국식 나이
    childAge: ({ children, createAt }) => {
      const createdYear = new Date(createAt).getFullYear();
      const birthYear = parseInt(children.birthYear);
      return createdYear - birthYear + 1;
    },
  },
};
