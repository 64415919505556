
import RoundAvatar from '@/components/common/img/RoundAvatar.vue';

export default {
  name: 'ReviewAdmin',
  components: { RoundAvatar },
  props: {
    adminComment: { type: String, required: true },
  },
  data() {
    return {
      adminIcon: `${process.env.CLOUD_FRONT}/images/development/gguge-admin.png`,
    };
  },
};
