
import ReviewHeader from '@/components/review/ReviewHeader.vue';
import ReviewContents from '@/components/review/ReviewContents.vue';
import ReviewHelpful from '@/components/review/ReviewHelpful.vue';

export default {
  name: 'ReviewItem',
  components: { ReviewHeader, ReviewContents, ReviewHelpful },
  props: {
    id: { type: Number, required: true },
    user: { type: Object, required: true },
    children: { type: Object, required: true },
    createAt: { type: String, required: true },
    rating: { type: Number, required: true },
    images: { type: Array, default: () => [] },
    comment: { type: String, default: '' },
    adminComment: { type: String, default: '' },
    supportCount: { type: Number, default: 0 },
    product: { type: Object, default: () => ({ id: 0, title: '' }) },
  },
  computed: {
    /**
     * @description
     * 리뷰 작성자의 아이 나이와 작성일을 노출하는 데 사용되는 데이터
     * replace 적용한 이유는 safari 15버전에서 new Date 객체가 안 만들어지는 포멧에 대응하기 위함.
     *
     * ex) createdAt: 2023-06-15 06:10:31 => 2023/06/15 06:10:31
     */
    replacedCreatedAt() {
      return this.createAt?.replace(/-/g, '/');
    },
  },
};
