
import { makeDateTextFormatKo } from '@/utils/dateFnsUtils.js';

export default {
  name: 'ReviewUser',
  props: {
    userName: { type: String, required: true },
    childAge: { type: Number, required: true },
    createAt: { type: String, required: true },
  },
  computed: {
    // 최대 5자까지 길이만 남기고 첫글자 제외 * 처리
    userNameMasked: ({ userName }) => {
      if (!userName) return '-';
      const maskedName = userName.split('').slice(0, 5);
      return maskedName[0] + Array(maskedName.length).join('*');
    },
    childAgeString: ({ childAge }) => (!childAge ? '-' : `${childAge}세`),
    createAtString: ({ createAt }) =>
      !createAt
        ? '-'
        : makeDateTextFormatKo(new Date(createAt), 'yyyy. M. d. aa h:mm:ss'),
  },
};
