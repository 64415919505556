import { render, staticRenderFns } from "./ReviewHeader.vue?vue&type=template&id=ef758cf2&scoped=true"
import script from "./ReviewHeader.vue?vue&type=script&lang=js"
export * from "./ReviewHeader.vue?vue&type=script&lang=js"
import style0 from "./ReviewHeader.vue?vue&type=style&index=0&id=ef758cf2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef758cf2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewUser: require('/codebuild/output/src3377776947/src/components/review/ReviewUser.vue').default,ReviewStars: require('/codebuild/output/src3377776947/src/components/review/ReviewStars.vue').default})
